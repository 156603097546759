const EVENTS = [
  'data-ready',
  'playback-progress',
  'player-click',
  'pre-destroy',
  ['ready', (instance) => instance.play()],
  'resize',
  'error',
  'VPAID-started',
]

export default function setPlayerEventListeners(instance) {
  const logger = (eventName, cb) => () => {
    if (cb) cb(instance)
  }
  EVENTS.forEach((event) => {
    const [eventName, cb] = Array.isArray(event) ? event : [event]
    instance.on(eventName, logger(eventName, cb))
  })
}
