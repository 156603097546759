// The intersection observer is responsible for detecting if the video player is inside the viewport.

import { THRESHOLD, INTERSECTIONRATIO } from './constant'

let prevRatio = 0.0

function handleIntersect(entries, observer) {
  entries.forEach((entry) => {
    if (
      entry.intersectionRatio > INTERSECTIONRATIO &&
      entry.intersectionRatio > prevRatio
    ) {
      window.playerInstance.play()
    } else if (
      entry.intersectionRatio < INTERSECTIONRATIO &&
      entry.intersectionRatio < prevRatio
    ) {
      window.playerInstance.pause()
    }

    prevRatio = entry.intersectionRatio
  })
}

export default function createIntersectionObserver() {
  const options = {
    root: null, // root is set to null as the container in which the observer is operating is the whole viewport
    rootMargin: '0px',
    threshold: THRESHOLD,
  }

  const target = document.querySelector('#videoContainer')
  const observer = new IntersectionObserver(handleIntersect, options)
  observer.observe(target)
}
