// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.gg-ez-vp {
  font-size: 10px !important;
}

button.gg-ez-vp--button-icon.play,
button.gg-ez-vp--button-icon.pause {
  height: 16px;
  width: 14px;
}

.gg-ez-vp--timestamp {
  position: absolute;
  right: 0;
}

input.gg-ez-vp--input-range {
  position: relative;
  bottom: 3px;
}

.gg-ez-vp--player-container .gg-ez-vp--viewer {
  height: auto !important;
}

.gg-ez-vp--progress-bar {
  top: 33px;
}

.gg-ez-vp--progress-bar .gg-ez-vp--progress-filled {
  background: #fba822;
}

.gg-ez-vp--timestamp-current {
  margin-left: 5px;
}

.gg-ez-vp--controls {
  height: auto!important;
}

.gg-ez-vp--touchscreen .gg-ez-vp--controls,
.gg-ez-vp:hover .gg-ez-vp--controls,
.gg-ez-vp--controls {
  transform: translateY(0) !important;
  bottom: 3px;
}

.gg-ez-vp:hover .gg-ez-vp--progress .gg-ez-vp--progress-bar {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;;EAGE,mCAAmC;EACnC,WAAW;AACb;;AAEA;EACE,SAAS;AACX","sourcesContent":["html,\r\nbody {\r\n  width: 100%;\r\n  height: 100%;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.gg-ez-vp {\r\n  font-size: 10px !important;\r\n}\r\n\r\nbutton.gg-ez-vp--button-icon.play,\r\nbutton.gg-ez-vp--button-icon.pause {\r\n  height: 16px;\r\n  width: 14px;\r\n}\r\n\r\n.gg-ez-vp--timestamp {\r\n  position: absolute;\r\n  right: 0;\r\n}\r\n\r\ninput.gg-ez-vp--input-range {\r\n  position: relative;\r\n  bottom: 3px;\r\n}\r\n\r\n.gg-ez-vp--player-container .gg-ez-vp--viewer {\r\n  height: auto !important;\r\n}\r\n\r\n.gg-ez-vp--progress-bar {\r\n  top: 33px;\r\n}\r\n\r\n.gg-ez-vp--progress-bar .gg-ez-vp--progress-filled {\r\n  background: #fba822;\r\n}\r\n\r\n.gg-ez-vp--timestamp-current {\r\n  margin-left: 5px;\r\n}\r\n\r\n.gg-ez-vp--controls {\r\n  height: auto!important;\r\n}\r\n\r\n.gg-ez-vp--touchscreen .gg-ez-vp--controls,\r\n.gg-ez-vp:hover .gg-ez-vp--controls,\r\n.gg-ez-vp--controls {\r\n  transform: translateY(0) !important;\r\n  bottom: 3px;\r\n}\r\n\r\n.gg-ez-vp:hover .gg-ez-vp--progress .gg-ez-vp--progress-bar {\r\n  margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
