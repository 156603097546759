// Librabies
import GgEzVp from 'gg-ez-vp'
import {
  handlePostMessage,
  POST_MESSAGE_HEL_VIDEO_DURATION,
  POST_MESSAGE_HEL_VIDEO_PAUSE,
  POST_MESSAGE_HEL_VIDEO_FINISH,
  POST_MESSAGE_HEL_VIDEO_START,
  POST_MESSAGE_HEL_VIDEO_PLAY,
} from '@opinary/barri'

// Constant
import { VAST_CONFIG, VAST_CONTROLS_OFF } from './config'

// Functions
import setPlayerEventListeners from './events'
import createIntersectionObserver from './intersectionObserver'

// Styles
import './style.css'
import 'gg-ez-vp/dist/gg-ez-vp.css'
import parseVastData from './parseVastUrl'

window.addEventListener('load', () => {
  const { videoUrl, hideControls, controls } = getUrlFromQueryParams()
  if (videoUrl) startVideo(videoUrl, hideControls, controls)

  handlePostMessage(POST_MESSAGE_HEL_VIDEO_START, async (event) => {
    startVideo(
      event.data.videoUrl,
      event.data.hideControls,
      event.data.controls
    )
  })
})

export const startVideo = (
  src = '',
  hideControls = false,
  controls = VAST_CONFIG.controls
) => {
  const vastConfig = {
    ...VAST_CONFIG,
    src: parseVastData(src),
    controls: hideControls
      ? false
      : {
          ...VAST_CONTROLS_OFF,
          ...controls,
        },
  }

  const ggEzVp = createPlayerInstance(vastConfig)
  window.playerInstance = ggEzVp

  // Set video player listeners
  ggEzVp.on('ended', sendVideoFinishPostMessage)
  ggEzVp.on('error', sendVideoFinishPostMessage)
  setPlayerEventListeners(ggEzVp, vastConfig)
  checkVideoProgress(ggEzVp)

  // Set page scroll listener
  createIntersectionObserver()
  // The next line is a hack to fix the play/pause button which display the wrong state after page loads
  window.playerInstance.on('data-ready', () => {
    window.playerInstance.pause()
    sendVideoDurationPostMessage(window.playerInstance.getDuration()) // Duration for mp4/vast
  })
  window.addEventListener('blur', () => window.playerInstance.pause())
  window.addEventListener('focus', () => window.playerInstance.play())

  handlePostMessage(POST_MESSAGE_HEL_VIDEO_PAUSE, () =>
    window.playerInstance.pause()
  )
  handlePostMessage(POST_MESSAGE_HEL_VIDEO_PLAY, () =>
    window.playerInstance.play()
  )
}

// fix issue when using VPAID, ggEzVp player only updates the intervals of 0%, 25%, 50%, 75%, 100%
export const checkVideoProgress = (ggEzVp) => {
  let intervalAdVideoCheck
  ggEzVp.on('AdVideoStart', () => {
    intervalAdVideoCheck = setInterval(() => {
      ggEzVp.VPAIDWrapper.checkProgress()
    }, 500)
    sendVideoDurationPostMessage(window.playerInstance.getDuration()) // Duration for VPAID
  })

  ggEzVp.on('AdVideoComplete', () => {
    clearInterval(intervalAdVideoCheck)
  })
}

export const createPlayerInstance = (config) => {
  const instance = new GgEzVp(config)
  return instance
}

export const getUrlFromQueryParams = () => {
  const url = new URL(window.location)
  return {
    videoUrl: url.searchParams.get('videoUrl'),
    controls: JSON.parse(url.searchParams.get('controls')) || undefined,
    hideControls: url.searchParams.get('hideControls') === 'true',
  }
}

export const sendVideoFinishPostMessage = () => {
  window.parent.postMessage({ type: POST_MESSAGE_HEL_VIDEO_FINISH }, '*')
}

export const sendVideoDurationPostMessage = (duration) => {
  window.parent.postMessage(
    { type: POST_MESSAGE_HEL_VIDEO_DURATION, duration },
    '*'
  )
}

export default createPlayerInstance
