export const VAST_CONFIG = {
  container: 'videoContainer',
  isVAST: true,
  autoplay: true,
  adControls: false,
  controls: {
    play: true,
    skip: false,
    expand: false,
    timestamp: true,
    volume: true,
    volumeRange: true,
    progress: true,
    timestampAd: false,
  },
}

export const VAST_CONTROLS_OFF = {
  play: false,
  skip: false,
  expand: false,
  timestamp: false,
  volume: false,
  volumeRange: false,
  progress: false,
  timestampAd: false,
}
