const parseVastData = (data) => {
  if (!data) return ''
  try {
    new URL(data)
    return data
  } catch (e) {
    const blob = new Blob([data], { type: 'text/xml' })
    return URL.createObjectURL(blob)
  }
}

export default parseVastData
